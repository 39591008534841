@charset "UTF-8";

@import "mixins";

.component .p_button, .p_button {
  border-radius: 0;
  color: var(--color-text);
  background: var(--color-surface);
  border: 4px solid var(--color-brand-weak);
  text-align: left;
  @include mq(tbl) {
    border-radius: 0;
  }
  &:hover {
    border-color: var(--color-brand-weaker);
  }
  &:before {
    background: center / contain no-repeat var(--arrow-button-b);
    width: 22px;
    height: 14px;
    right: 12px;
    @include mq(tbl) {
      right: 14px;
    }
  }
  &.cv {
    border-radius: 0;
    padding: var(--space-medium) var(--space-large);
    &:before {
      content: '';
      right: 14px;
      @include mq(tbl) {
        right: 16px;
      }
    }
  }
  &.reverse {
    color: var(--color-text) !important;
    &:before {
      background: center / contain no-repeat var(--arrow-button-b);
      width: 19px;
      height: 9px;
      right: 12px;
      @include mq(tbl) {
        right: 14px;
      }
    }
    &.cv {
      border-radius: 0;
    }
  }
}
.component.type_catchcopy_image_bg {
  @include mq(tbl) {
    padding-bottom: 70px;
  }
  .inner .text {
    @include mq(tbl) {
      bottom: -70px;
    }
  }
}
.component.type_catchcopy .inner .copy .button a,
.component.type_catchcopy_image_bg .inner .text .button a,
.component.type_conversion .inner .button a {
  text-align: left;
}